import React, { useState } from "react";
import { BsFillCaretRightFill, BsFillCaretDownFill } from "react-icons/bs";

import classes from "./Homelab.module.scss";
import HomelabNetwork from "./HomelabNetwork/HomelabNetwork";
import HomelabServices from "./HomelabServices/HomelabServices";

const Homelab = () => {
	const [servicesActive, setServicesActive] = useState(false);
	const [classnameServices, setClassnameServices] = useState(
		classes.containerDropDown__services
	);

	const [networkActive, setNetworkActive] = useState(false);
	const [classnameNetwork, setClassnameNetwork] = useState(
		classes.containerDropDown__network
	);

	const [faqActive, setFaqActive] = useState(false);
	const [classnameFaq, setClassnameFaq] = useState(
		classes.containerDropDown__faq
	);

	const servicesDropDownHandler = () => {
		setServicesActive(!servicesActive);
		servicesActive
			? setClassnameServices(classes.containerDropDown__services)
			: setClassnameServices(classes.containerDropDown__servicesActive);
	};

	const networkDropDownHandler = () => {
		setNetworkActive(!networkActive);
		networkActive
			? setClassnameNetwork(classes.containerDropDown__network)
			: setClassnameNetwork(classes.containerDropDown__networkActive);
	};

	const faqDropDownHandler = () => {
		setFaqActive(!faqActive);
		faqActive
			? setClassnameFaq(classes.containerDropDown__faq)
			: setClassnameFaq(classes.containerDropDown__faqActive);
	};

	return (
		<div className={classes.homeLabContainer}>
			<p>
				I find&nbsp;
				<a
					href="https://www.docker.com/"
					className={classes.serviceItemTitle}
				>
					Docker
				</a>
				&nbsp;simple and straightforward and use it to manage all my
				services. I use&nbsp;
				<a
					href="https://opnsense.org/"
					className={classes.serviceItemTitle}
				>
					OPNSense
				</a>
				&nbsp;as my router and a&nbsp;
				<a
					href="https://store.ui.com/collections/operator-edgemax-switches/products/edgeswitch-16-150w"
					className={classes.serviceItemTitle}
				>
					16-port 150W Ubiquity switch
				</a>
				.
			</p>
			<p>
				<b>Network layout</b> and <b>Services</b> contain the basic
				network layout I have in my lab and some of the services I host.
			</p>

			<div
				className={classes.containerDropDown}
				onClick={networkDropDownHandler}
			>
				<span className={classes.rightTriangle}>
					{networkActive ? (
						<BsFillCaretDownFill />
					) : (
						<BsFillCaretRightFill />
					)}
				</span>
				&nbsp;&nbsp;<strong>Network layout</strong>
				<div className={classnameNetwork}>
					<HomelabNetwork />
				</div>
			</div>

			<div
				className={classes.containerDropDown}
				onClick={servicesDropDownHandler}
			>
				<span className={classes.rightTriangle}>
					{servicesActive ? (
						<BsFillCaretDownFill />
					) : (
						<BsFillCaretRightFill />
					)}
				</span>
				&nbsp;&nbsp;<strong>Services</strong>
				<div className={classnameServices}>
					<HomelabServices />
				</div>
			</div>

			<div className={classes.containerDropDown}>
				<div
					className={classes.portfolioFaqTitle}
					onClick={faqDropDownHandler}
				>
					{faqActive ? (
						<BsFillCaretDownFill className={classes.contentIcon} />
					) : (
						<BsFillCaretRightFill className={classes.contentIcon} />
					)}
					<b>FAQs</b>
				</div>
				<div className={classnameFaq}>
					<div className={classes.portfolioQ}>
						Why are you hosting Valheim and Terraria?
					</div>
					<div className={classes.portfolioA}>
						I got fed up of the random power cuts and the resulting
						loss of internet access. So I decided to host them
						locally using a UPS (Uninterrupted Power Suppply).
					</div>
					<div className={classes.portfolioQ}>
						What made you decide to start hosting services yourself?
					</div>
					<div className={classes.portfolioA}>
						It all started when I was learning to dual boot my
						laptop with Arch Linux and Windows. Tinkering in Linux
						led me to wanting to mess around with servers. That and
						the fact that I worked at BYUI's datacenter made me want
						to start hosting things on my own.
					</div>
				</div>
			</div>
		</div>
	);
};

export default Homelab;
