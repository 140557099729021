import React from "react";

import classes from "./HomelabServices.module.scss";

const HomelabServices = () => {
  return (
    <div className={classes.servicesContainer}>
      <div className={classes.serviceItemContainer}>
        <a href="https://www.truenas.com/" className={classes.serviceItemTitle}>
          TrueNAS
        </a>
        &nbsp;&nbsp;&#8594;&nbsp;I back up all my data using this service. I run
        this in MIRROR state. Every other service uses this server for storage
        and backups.
      </div>
      <div className={classes.serviceItemContainer}>
        <a href="https://nextcloud.com/" className={classes.serviceItemTitle}>
          NextCloud
        </a>
        &nbsp;&nbsp;&#8594;&nbsp;This is my Google Drive - Personal Edition.
      </div>
      <div className={classes.serviceItemContainer}>
        <a href="https://heimdall.site/" className={classes.serviceItemTitle}>
          Heimdall
        </a>
        &nbsp;&nbsp;&#8594;&nbsp;I have all my services route from here. It
        makes my life easier because I do not have to remember numerous IPs.
      </div>
      <div className={classes.serviceItemContainer}>
        <a href="https://emby.media/" className={classes.serviceItemTitle}>
          Emby
        </a>
        &nbsp;&nbsp;&#8594;&nbsp;I host my music, TV Shows and Movies here.
      </div>
      <div className={classes.serviceItemContainer}>
        <a href="https://js.wiki/" className={classes.serviceItemTitle}>
          Wiki.js
        </a>
        &nbsp;&nbsp;&#8594;&nbsp;I use this service to document everything that
        I do. I feel that writing them down helps me be more productive.
      </div>
      <div className={classes.serviceItemContainer}>
        <a href="https://bitwarden.com/" className={classes.serviceItemTitle}>
          BitWarden
        </a>
        &nbsp;&nbsp;&#8594;&nbsp;I use this instead of{" "}
        <a
          href="https://www.lastpass.com/"
          className={classes.serviceItemTitle}
        >
          LastPass
        </a>
        . I used to use{" "}
        <a
          href="https://www.keepassdx.com/"
          className={classes.serviceItemTitle}
        >
          KeePassDX
        </a>
        , but soon reverted to this as it is easier to have it centralized
        locally.
      </div>
      <div className={classes.serviceItemContainer}>
        <a
          href="https://www.valheimgame.com/"
          className={classes.serviceItemTitle}
        >
          Valheim
        </a>
        &nbsp;&nbsp;&#8594;&nbsp;I absolutely love Valheim! It is a tonne of fun
        and I think I spend too much time on it. I love to wander and explore,
        but end up dying to wolves more often than not.
      </div>
      <div className={classes.serviceItemContainer}>
        <a
          href="https://www.terraria.org/"
          className={classes.serviceItemTitle}
        >
          Terraria
        </a>
        &nbsp;&nbsp;&#8594;&nbsp;I used to play a lot of
        <a
          href="https://www.dota2.com/home"
          className={classes.serviceItemTitle}
        >
          {" "}
          Dota2
        </a>
        , but decided to learn a game that was not as intense. I tried Terraria
        and loved it! So I host it locally and play it at my convenience.
      </div>
    </div>
  );
};

export default HomelabServices;
