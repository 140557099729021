import React from "react";
import { NavLink } from "react-router-dom";

import classes from "./Header.module.scss";

const Header = () => {
	return (
		<div className={classes.nav}>
			{/* Benjamin Dass */}
			<span className={classes.headerTitle}>Benjamin Dass</span>

			{/* Navlinks */}
			<ul className={classes.navList}>
				<li>
					<div className={classes.navListElement}>
						<NavLink to="/home" activeClassName={classes.active}>
							Home
						</NavLink>
					</div>
				</li>
				<li>
					<div className={classes.navListElement}>
						<NavLink
							to="/experience"
							activeClassName={classes.active}
						>
							Experience
						</NavLink>
					</div>
				</li>
				<li>
					<div className={classes.navListElement}>
						<NavLink
							to="/portfolio"
							activeClassName={classes.active}
						>
							Projects
						</NavLink>
					</div>
				</li>
				<li>
					<div className={classes.navListElement}>
						<NavLink
							to="/letsConnect"
							activeClassName={classes.active}
						>
							Let's Connect
						</NavLink>
					</div>
				</li>
			</ul>
		</div>
	);
};

export default Header;
