import React from "react";

import classes from "./HomelabNetwork.module.scss";

const HomelabNetwork = () => {
	return (
		<div className={classes.containerNetwork}>
			<div>
				I use OPNSense as my router and firewall. I have set up VLANs to
				segregate my services. For example, those that require
				adminstrator privileges (OPNSense, switch, etc.) and those that
				are used for entertainment (Emby, Heimdall, etc.) are on
				separate VLANs.
			</div>
			<div>
				If you would like to know more about my network set up, I'd be
				happy to connect with you and show you. It is nothing crazy, but
				it is nice to go to bed knowing things are where they need to
				be.
			</div>
		</div>
	);
};

export default HomelabNetwork;
