import React from "react";
import { useState } from "react";
import { send } from "emailjs-com";

import classes from "./LetsConnect.module.scss";
import { useTransition, animated } from "react-spring";

const LetsConnect = () => {
	const SERVICE_ID = "service_dhjgkoj";
	const TEMPLATE_ID = "template_q15u1pf";
	const USER_ID = "user_ElHoDo55S1N4ahnYLrOOl";

	const [toSend, setToSend] = useState({
		from_name: "",
		reply_to: "",
		message: "",
	});
	const [error, setError] = useState("");
	const [success, setSuccess] = useState("");

	const handleChange = (e) => {
		setToSend({ ...toSend, [e.target.name]: e.target.value });
	};

	const onSubmit = (e) => {
		if (toSend.from_name === "") {
			setError("Please include your name");
		} else if (toSend.reply_to === "") {
			setError("Please include your email");
		} else if (toSend.message === "") {
			setError("Please include a message");
		} else {
			setSuccess("Processing...");
			e.preventDefault();
			setError("");
			send(SERVICE_ID, TEMPLATE_ID, toSend, USER_ID)
				.then((response) => {
					console.log("SUCCESS!", response.status, response.text);
					setSuccess("Message sent!");
				})
				.catch((err) => {
					setError(err);
				});
			setToSend({ from_name: "", reply_to: "", message: "" });
		}
	};

	let loaded = true;
	const transitions = useTransition(loaded, {
		delay: 100,
		from: { opacity: 0 },
		enter: { opacity: 1 },
		leave: { opacity: 0 },
	});

	return transitions(
		(styles, item) =>
			item && (
				<animated.div style={styles}>
					<div className={classes.header}>
						<div className={classes.headerTitle}>Lets Connect</div>
						<div className={classes.headerContent}>
							I enjoy meeting new people and would love to
							connect. If you have any questions, or would like to
							work with me, please feel free to send me a message
							below or reach me on{" "}
							<a
								className={classes.linkedIn}
								href="https://www.linkedin.com/in/benjamindass/"
								target="_blank"
								rel="noreferrer"
							>
								LinkedIn
							</a>
							. I will reply as soon as possible.
						</div>
					</div>
					<div className={classes.container}>
						<div className={classes.element}>
							<input
								className={classes.inputField}
								type="text"
								name="from_name"
								placeholder="Name"
								value={toSend.from_name}
								onChange={handleChange}
								required
							/>
						</div>
						<div className={classes.element}>
							<input
								className={classes.inputField}
								type="text"
								name="reply_to"
								placeholder="Your email"
								value={toSend.reply_to}
								onChange={handleChange}
								required
							/>
						</div>
						<div className={classes.element}>
							<textarea
								className={classes.messageArea}
								type="text"
								name="message"
								placeholder="Your message"
								value={toSend.message}
								onChange={handleChange}
								required
							/>
						</div>
						<div className={classes.element}>
							<button
								className={classes.submitButton}
								onClick={onSubmit}
							>
								Connect
							</button>
						</div>
						{error === "" && (
							<p className={classes.successState}>{success}</p>
						)}
						{error !== "" && (
							<p className={classes.errorState}>{error}</p>
						)}
					</div>
				</animated.div>
			)
	);
};

export default LetsConnect;
