import React from "react";
import Homelab from "./Homelab/Homelab";
import PeaceJoyEternaLife from "./PeaceJoyEternaLife/PeaceJoyEternaLife";

import classes from "./Portfolio.module.scss";
import { useTransition, animated } from "react-spring";

const Portfolio = () => {
	let loaded = true;
	const transitions = useTransition(loaded, {
		delay: 100,
		from: { opacity: 0 },
		enter: { opacity: 1 },
		leave: { opacity: 0 },
	});

	return transitions(
		(styles, item) =>
			item && (
				<animated.div style={styles}>
					<div className={classes.portfolioContainer}>
						<div className={classes.titlePortfolio}>Projects</div>
						<div className={classes.porfolioDescription}>
							<p>
								I enjoy working on projects as they provide me
								with a creative outlet, satisfy my desire to
								learn new things, gratify my curiosity and allow
								me the freedom to figure things out as I go.
							</p>
							<p>
								My aim as I develop a project is to produce an
								end product that is as stable and predictable as
								it can be while being customized. To achieve
								this result I prefer to choose long-term
								solutions to short-term fixes.
							</p>
							<p>
								The following are projects that I worked on / am
								still developing while studying full credits
								each semester. I have run into several errors
								and stayed up many nights trying to fix them.
								The joy and overwhelming sense of achievement I
								feel upon completing the project compensates
								wonderfully for the trouble.
							</p>
						</div>
						<div>
							<div className={classes.titlePortfolioItem}>
								PeaceJoyEternaLife.com
							</div>
							<div
								className={classes.titlePortfolioItemContainer}
							>
								<PeaceJoyEternaLife />
							</div>
							<div className={classes.titlePortfolioItem}>
								HomeLab
							</div>
							<div
								className={classes.titlePortfolioItemContainer}
							>
								<Homelab />
							</div>
						</div>
					</div>
				</animated.div>
			)
	);
};

export default Portfolio;
