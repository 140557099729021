import React from "react";
import { AiOutlineUser } from "react-icons/ai";
import { RiLockPasswordLine } from "react-icons/ri";

import classes from "./PeaceJoyEternaLife.module.scss";

const PeaceJoyEternaLife = () => {
	return (
		<div className={classes.pjelContainer}>
			<span className={classes.pjelContent}>
				My mom wanted a place to share her thoughts, some of her life
				experiences, and her delicious Indian recipes. This is a work in
				progress.
			</span>
			<br />
			<span className={classes.pjelContent}>
				The site below is a demo branch of the master site. Please do
				not save data onto this site as it will be deleted.
			</span>
			<br />
			<div className={classes.linkAuthContainer}>
				<a
					href="https://bdemo.benjamindass.com/login"
					target="_blank"
					rel="noreferrer"
					className={classes.pjelLink}
				>
					PeaceJoyEternaLife.com
				</a>

				<div className={classes.authContainer}>
					<span className={classes.pjelContentAuth}>
						<AiOutlineUser className={classes.authIcon} />
						&nbsp;&nbsp;&nbsp;&nbsp;
						<span className={classes.authContent}>
							demo@demo.com
						</span>
					</span>
					<span className={classes.pjelContentAuth}>
						<RiLockPasswordLine className={classes.authIcon} />
						&nbsp;&nbsp;&nbsp;&nbsp;
						<span className={classes.authContent}>demo12345</span>
					</span>
				</div>
			</div>
		</div>
	);
};

export default PeaceJoyEternaLife;
