import React from "react";

import classes from "./Job.module.scss";

const Job = (props) => {
	const icon = props.job.icon;
	const companyName = props.job.companyName;
	const jobTitle = props.job.jobTitle;
	const startDate = props.job.startDate;

	const endDate = props.job.endDate;
	const currentJob = props.job.currentJob;
	const end = currentJob ? "current" : endDate;

	const jobExperience = props.job.jobExperience;

	return (
		<div className={classes.jobContainer}>
			<div>
				<img src={icon} className={classes.images} alt={companyName} />
			</div>

			<div className={classes.companyName}>{companyName}</div>
			<div className={classes.jobTitle}>{jobTitle}</div>
			<div className={classes.dates}>
				{startDate} - {end}
			</div>

			<ul className={classes.experience}>
				{jobExperience.map((item) => (
					<li key={Math.random() * 10000}>{item}</li>
				))}
			</ul>
		</div>
	);
};

export default Job;
