import React from "react";
import Job from "./Job/Job";
import jobData from "./jobs";

import classes from "./Experience.module.scss";
import { useTransition, animated } from "react-spring";

const Experience = () => {
	let loaded = true;
	const transitions = useTransition(loaded, {
		delay: 100,
		from: { opacity: 0 },
		enter: { opacity: 1 },
		leave: { opacity: 0 },
	});

	return transitions(
		(styles, item) =>
			item && (
				<animated.div style={styles}>
					<div className={classes.experienceContainer}>
						{jobData.map((jobItem) => (
							<Job key={jobItem.id} job={jobItem} />
						))}
					</div>
				</animated.div>
			)
	);
};

export default Experience;
