const jobData = [
  {
    id: 4,
    icon: "/StockMotion.png",
    companyName: "StockMotion",
    jobTitle: "Software Engineer",
    jobExperience: [
      "Currently working on the front-end UI and features using Flutter.",
      "Creating Firebase functions in TypeScript and endpoints for frontend applications.",
      "Reworked backend endpoints to new database contract.",
      "Created new backend endpoints according to the new features being implemented.",
      "Tested all new features being implemented on the backend.",
      "Created JEST unit tests to test the endpoints that are about to be released into production.",
      "Worked with IEX Cloud API to figure out costs and qoutes on trades.",
      "Created documentation of the backend for other teams: informational and understanding backend types.",
    ],
    startDate: "Aug 2020",
    endDate: "-",
    currentJob: true,
  },
  {
    id: 3,
    icon: "/BYUI.png",
    companyName: "Brigham Young University - Idaho",
    jobTitle: "Asistant Network Engineer",
    jobExperience: [
      "Planned and replaced old with new network core equipment for the entire campus across 2 redundant data centers; Staged fiber links for the new core (transitioned with zero error on implementation day), consolidated from multiple to single rack layer-3 routers and switches, and then removed old network core and links (with zero error as well).",
      "Worked one-on-one with a Network Engineer on updating, upgrading, patching, and configuring Cisco switches, Cisco routers, Cisco voice gateways, and Xirrus and Aruba access points. Cleaned up Cisco switch.",
      "Reserved and allocated static/DHCP-reserved IPs for various equipment throughout campus and as requested by departments.",
      "Cleaned up old MAC records stored in Cisco Identity Services Engine campus-wide. Used RESTful API to do bulk cleaning operations using XML, HTTP headers and GET/POST requests.",
      "Managed incoming DNS requests by adding new names to associated IPs.",
      "Configured and deployed a campus wide, Aruba wireless solution at a volume of 1200+ access points.",
    ],
    startDate: "Dec 2017",
    endDate: "-",
    currentJob: true,
  },
  {
    id: 2,
    icon: "/AmericaHealth.png",
    companyName: "AmericaHealth",
    jobTitle: "Software Engineer - Team Lead",
    jobExperience: [
      "Assisted AmericaHealth in fully transitioning to a new EHR system while maintaining HIPAA compliance.",
      "Solely managed and architect cloud solutions for AmericaHealth.",
      "Built out CI/CD pipelines with AWS CodeDeploy, CodeBuild and CodePipeline for the EHR system.",
      "Planned and set up the stack of the new EHR system.",
      "Planned and assisted with tasks, organized and led a team of 4 engineers to build features for the company.",
      "Assisted in incoperating NewCropRX API lab reports.",
      "Worked on fixing bugs that existed throughout the EHR System.",
      "Worked on troubleshooting the scraper not scraping particular documents.",
      "Worked toward building a productful and encouraging environment, where engineers experienced success and work place satisfaction",
      "Built out policies and created guidelines to encourage good structure in the work environment.",
      "Handled interviews, progressed to hiring and led new interns to performing well at their responsibilities.",
    ],
    startDate: "Jan 2021",
    endDate: "Apr 2021",
    currentJob: false,
  },
  {
    id: 1,
    icon: "/AmericaHealth.png",
    companyName: "AmericaHealth",
    jobTitle: "Software Engineer - Intern",
    jobExperience: [
      "Integrated an email and text reminder system to the current Electronic Health Record system to remind patients of their upcoming appointments using Twillio's API.",
      "Completed building a cloud network for the EHR system, using AWS resources namely: RDS, KMS, EC2, VPC, Load Balancing, IAM profiles and roles, CFN, public and private subnets, and implementing pertaining inbound and outbound rules for the world, web server and MySQL instances.",
      "Completed making Docker images of our EHR system, and further attached it to an AWS AMI. When a new AWS CFN stack is made, it automatically pulls the AMI and thus the AMI builds the image from DockerHub from the network that has been created.",
      "Planned and mapped out a new EHR system: design, tools and mock up.",
    ],
    startDate: "Aug 2020",
    endDate: "Dec 2020",
    currentJob: false,
  },

  {
    id: 0,
    icon: "/BYUI.png",
    companyName: "Brigham Young University - Idaho",
    jobTitle: "Asistant System Administrator",
    jobExperience: [
      "Monitored server dashboards (server temperature, CPU usage, and server activity) and conducted physical checks (data center environments – in-rack temperatures, data center humidity, and static) at regular 30-minute intervals during day and night shifts to ensure jobs were running as scheduled.",
      "Reported issues (e.g jobs running for longer than expected, jobs using more CPU resources than expected, jobs running when they were not supposed to run) to system administrators and problem-solved based on findings and generated reports.",
      "Maintained an up-to-date list of data center servers and learned the functions of various types of servers depending on differing campus department needs.",
      "Managed (set up new accounts, taught new account usage) and maintained (kept account database current, resolved account-related requests such as password-related issues) Feith and iSeries accounts for the Admissions, Financial Aid, and Student Records and Registration departments on campus.",
    ],
    startDate: "Nov 2016",
    endDate: "Dec 2017",
    currentJob: false,
  },
];

export default jobData;
