import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";

import Header from "./Header/Header";
import Home from "./Home/Home";
import Experience from "./Experience/Experience";
import Portfolio from "./Portfolio/Portfolio";
import LetsConnect from "./LetsConnect/LetsConnect";

import classes from "./App.module.scss";

function App() {
	return (
		<>
			<header>
				<div className={classes.headerComp}>
					<Header />
				</div>
			</header>
			<div className={classes.appBody}>
				<Switch>
					<Route path="/" exact>
						<Redirect to="/home" />
					</Route>
					<Route path="/home" component={Home} />
					<Route path="/experience" component={Experience} />
					<Route path="/portfolio" component={Portfolio} />
					<Route path="/letsConnect" component={LetsConnect} />
				</Switch>
			</div>
		</>
	);
}

export default App;
