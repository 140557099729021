import React from "react";

import classes from "./Home.module.scss";
import { useTransition, animated } from "react-spring";

const Home = () => {
	let loaded = true;

	const transitions = useTransition(loaded, {
		delay: 100,
		from: { opacity: 0 },
		enter: { opacity: 1 },
		leave: { opacity: 0 },
	});

	return transitions(
		(styles, item) =>
			item && (
				<animated.div style={styles}>
					<div className={classes.homeContainer}>
						{/* About Me */}
						<div className={classes.aboutMeContainer}>
							<div className={classes.contentHeader}>
								About Me
							</div>
							<div className={classes.aboutMeContent}>
								<div className={classes.content}>
									<p>
										Hi, my name is Benjamin. I am a
										self-driven programmer who derives great
										satisfaction in designing and building
										software products that meet and satisfy
										customer needs, empowering them to
										achieve their goals. I love the
										challenge of creating new software and
										methods to assist with product
										development. I have a great work ethic
										with the value of integrity at the top
										of the list. I especially enjoy working
										with people who are as motivated as I am
										in thinking up innovative designs or
										solutions to problems.
									</p>
								</div>
								<img
									src="/me.jpg"
									alt="Benjamin Dass"
									width="200"
									height="190"
								/>
							</div>
						</div>

						{/* Residence banner */}
						<div className={classes.residence}>
							<div className={classes.residenceItem}>
								<span>
									<b>Born in:</b> &nbsp; Klang, Malaysia
								</span>
								<span className={classes.location}>
									3°8'28.32" N, 101°41'11.51" E
								</span>
								<img
									className={classes.flag}
									src="/myflag.png"
									alt="MY flag"
									width="25"
									height="15"
								/>
							</div>
							<div div className={classes.residenceItem}>
								<span>
									<b>Living in:</b> &nbsp; Rexburg, Idaho
								</span>
								<span className={classes.location}>
									43°27'59.69" N, 112°2'2.9" W
								</span>
								<img
									className={classes.flag}
									src="/usflag.png"
									alt="US flag"
									width="25"
									height="15"
								/>
							</div>
						</div>

						{/* Background */}
						<div className={classes.backgroundContainer}>
							<span className={classes.contentHeader}>
								Background
							</span>
							<div className={classes.content}>
								<p>
									As a young boy, I spent many hours tinkering
									with our old home computer. Sometimes it
									yielded positive results, and at other
									times, not so good, especially when the
									computer took fifteen minutes to load.
									(Oops!) I would try and upgrade the hardware
									and tune the software myself. I bought and
									added RAM and GPU (ATI Radeon, way back in
									the day!), fixed random memory errors and
									attempted to speed up the computer - all
									this being done without my parents'
									knowledge.
								</p>
								<p>
									At college, I initially majored in Chemistry
									for my undergraduate studies as I wanted to
									become a cardio-thoracic surgeon.
									(Inspiration from Russell M Nelson and
									Grey's Anatomy.) About a third of the way
									through the program, I realized that medical
									school was quite expensive and beyond my
									reach. Acting on my mom's suggestion that I
									consider pursuing a major in software
									engineering, I discovered my passion.
									Learning to code and finding solutions to
									problems excited me as they felt like forms
									of art.
								</p>
								<p>
									Subsequently, I was blessed tremendously
									with opportunities at AmericaHealth,
									StockMotion, and BYUI, to work with
									wonderful and knowledgable people who
									encouraged my curiosity to know more about
									how and why things work, and who inspired me
									with their dedication and passion for the
									job.
								</p>
							</div>
						</div>
					</div>
				</animated.div>
			)
	);
};

export default Home;
